export default {
  async mounted() {
    this.setupEvents();
  },
  methods: {
    checkMessage(event, isOpened) {
      let json;
      try {
        json = JSON.parse(event.data);
      } catch (event) {
        return;
      }
      if (json) {
        if (json.message === 'cm_loading') {
          isOpened = true;
        }
        if (json.message === 'remove_iframe' || (!isOpened && json.message === 'submit_preferences')) {
          window.location.reload();
        }
      }
    },
    setupEvents() {
      const trustarcOrigin = 'https://consent-pref.trustarc.com';
      const baseUrl = this.$url.getBaseURL();
      let iframeWasOpen = false;
      window.addEventListener(
        'message',
        (event) => {
          if (![trustarcOrigin, baseUrl].includes(event.origin)) return;
          this.checkMessage(event, iframeWasOpen);
        },
        false
      );
    },
  },
};
