export const LINKS = {
  COOKIESPOLICY: {
    HWRWD: 'cookiespolicy.php',
    SPWA: 'st/cookiespolicy/',
  },
  SECURITYPRIVACY: {
    HWRWD: 'securityprivacy.php',
    SPWA: 'st/securityprivacy/',
  },
  HOSTELTERMSANDCONDITIONS: {
    HWRWD: 'hosteltermsandconditions.php',
    SPWA: 'st/hosteltermsandconditions/',
  },
};
