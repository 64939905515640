<template>
  <div class="main">
    <!-- LanguagePicker must be rendered on server-side in order for language links to be crawlable -->
    <language-picker v-show="isLanguagePickerOpen" />

    <client-only>
      <wds-setup />
      <currency-picker v-if="isCurrencyPickerOpen" />
      <hw-sustainability-popup v-if="isSustainabilityPopupOpen" />
    </client-only>

    <Nuxt />
  </div>
</template>

<script>
import { WdsSetup } from '@wds/components';
import { GA_ACCOUNTS } from '~/config/analytics';
import { LINKS } from '~/config/links';
import { mapGetters } from 'vuex';
import trustarcListener from '~/mixins/Trustarc/trustarcListener';
import LanguagePicker from '~/components/common/LanguagePicker';

export default {
  mixins: [trustarcListener],

  components: {
    WdsSetup,
    LanguagePicker,
    CurrencyPicker: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/common/CurrencyPicker/index'),
    HwSustainabilityPopup: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/common/HwSustainabilityPopup/index'),
  },

  head() {
    const COOKIESPOLICYURL = LINKS.COOKIESPOLICY.SPWA;
    const SECURITYPRIVACYURL = LINKS.SECURITYPRIVACY.SPWA;
    const trustArcBaseUrl = `${this.$store.$env['BASE_URL_PROTOCOL']}://${
      this.$store.$env[this.$store.state.session.language.baseUrl]
    }/`;

    const trustArc = [
      {
        async: true,
        crossorigin: 'anonymous',
        fetchpriority: 'high',
        src:
          'https://consent.trustarc.com/notice?domain=hostelworld.com' +
          '&c=teconsent' +
          '&gtm=1' +
          '&js=nj' +
          '&noticeType=bb' +
          '&language=' +
          this.$store.state.session.language.lang +
          '&language=true' +
          '&text=true' +
          '&cookieLink=' +
          encodeURIComponent(`${trustArcBaseUrl}${COOKIESPOLICYURL}`) +
          '&privacypolicylink=' +
          encodeURIComponent(`${trustArcBaseUrl}${SECURITYPRIVACYURL}`),
      },
    ];

    const { web_hotjar: experiment_hotjar_enabled, web_googleTagManager: experiment_gtm_enabled } =
      this.$optimizely.isFeatureListEnabled(['web_hotjar', 'web_googleTagManager']);

    const hotjar = experiment_hotjar_enabled
      ? [
          {
            async: true,
            defer: true,
            src: '//u.hwstatic.com/hw/hotjar/hotjar.js',
          },
        ]
      : [];

    const gtm = experiment_gtm_enabled
      ? [
          {
            async: true,
            src: `https://www.googletagmanager.com/gtag/js?id=${GA_ACCOUNTS[0].ID}`,
          },
        ]
      : [];

    const headerScripts = [...trustArc, ...hotjar, ...gtm];

    return {
      meta: [
        {
          name: 'theme-color',
          content: '#56228B',
        },
      ],
      htmlAttrs: { lang: this.$store.state.session.language.lang },
      script: headerScripts,
    };
  },

  computed: {
    ...mapGetters({
      isLanguagePickerOpen: 'session/isLanguagePickerOpen',
      isCurrencyPickerOpen: 'session/isCurrencyPickerOpen',
      isSustainabilityPopupOpen: 'session/isSustainabilityPopupOpen',
    }),
  },

  beforeMount() {
    this.$storeChannelAttribution(null, this.$store.state.session.entryPoint);
  },

  async fetch() {
    await this.trackExperiments();
  },

  fetchOnServer: false,

  methods: {
    async trackExperiments() {
      const tracking = await this.$tracking.Layout();
      await tracking.onSocialBannerExperiment();
      await tracking.onNewSortOrderExperiment();
      await tracking.onSustainabilityExperiment();
    },
  },
};
</script>

<style lang="scss">
html body a {
  fill: $wds-color-orange-light;
  color: $wds-color-orange-light;
  outline: none;

  &:focus,
  &:focus-visible,
  &:active,
  &:visited {
    fill: $wds-color-orange-light;
    color: $wds-color-orange-light;
    text-decoration: none;
  }

  &:hover {
    fill: $wds-color-orange;
    color: $wds-color-orange;
    text-decoration: none;
  }

  &:focus-visible {
    outline: wds-rem(2px) solid $wds-color-ink-darker;
  }
}

.main {
  max-width: 100%;
  margin: auto 0;
  min-height: 100vh;
}
</style>
