<template>
  <div class="language-picker">
    <wds-item-picker
      :is-open="isLanguagePickerOpen"
      :heading="$t('t_LANGUAGE')"
      :items="getLanguagesList"
      :selected="getCurrentLanguage"
      :is-modal="!isExtraSmallScreen"
      :search-bar="false"
      @close-item-picker="toggleLanguagePicker(false)"
      @item-selected="handleLanguageSelected"
    />
  </div>
</template>

<script>
import mq from '~/mixins/mq';
import { WdsItemPicker } from '@wds/components';
import { mapGetters, mapActions } from 'vuex';
import { LANGUAGES, DEFAULT_LANGUAGE } from '~/config/languages';
import { UrlLanguage } from '~/lib/urlLanguage';
import { setHref } from '~/lib/dom';

export default {
  name: 'LanguagePicker',
  mixins: [mq],
  components: {
    WdsItemPicker,
  },
  computed: {
    ...mapGetters({
      isLanguagePickerOpen: 'session/isLanguagePickerOpen',
      getCurrentLanguage: 'session/getCurrentLanguage',
    }),
    getLanguagesList() {
      return LANGUAGES.map((language) => {
        const langUrl = this.$store.state.route?.langUrls[language.tag];
        return {
          label: language.name,
          value: language.lang,
          href: langUrl || null,
        };
      });
    },
  },
  methods: {
    ...mapActions({
      toggleLanguagePicker: 'session/toggleLanguagePicker',
    }),
    handleLanguageSelected(language) {
      const selectedLang = language || DEFAULT_LANGUAGE.lang;
      const currentURL = new URL(window.location.href);
      const urlLanguage = new UrlLanguage(this.$store, currentURL.host, this.$route.path, LANGUAGES);
      setHref(urlLanguage.buildLangUrl(selectedLang));
      this.toggleLanguagePicker(false);
    },
  },
};
</script>
